import React, { FC, useEffect, useState } from 'react';
import logoutHandler from 'utils/logoutHandler';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {setLogged, setUser} from "../../redux/actionCreators/userActions";
import {useRouter} from "next/router";
import { useCookies } from 'react-cookie';
import {isEmptyObject} from "../../utils/helper";

interface IUserDropdownProps {
  isUserPopupActive: boolean;
  isMobile: boolean;
}

interface IHasSrc extends HTMLImageElement {
  src: string;
}

interface IUserData {
  company: string;
  first_name: string;
  logo: string;
  validity: string;
  isAdmin: boolean;
  type: string;
}

const UserDropdown: FC<IUserDropdownProps> = ({ isUserPopupActive, isMobile }): React.ReactElement => {
  const router = useRouter();

  const dispatch = useDispatch();
  const {user, logged} = useSelector((state: RootState) => state.userData);
  const [{ user: userStore }, _, remove] = useCookies(['user']);

  const [userData, setUserState] = useState<IUserData | null>(user || userStore);

  if (!logged) return <div></div>;
  if (isEmptyObject(userData)) return <div></div>;

  const generateValidityDate = () => {
    if (userData?.validity) {
      return new Intl.DateTimeFormat('en-US', validityDateOptions).format(new Date(userData.validity));
    } else {
      return 'no data';
    }
  };

  const userProfileStatus = userData?.isAdmin ? 'admin' : 'member';
  const validityDateOptions = { year: 'numeric', month: 'short', day: 'numeric' } as const;
  const validityDate = generateValidityDate();

  const logout = () => {
    logoutHandler(() => {
      dispatch(setUser(null));
      dispatch(setLogged(false));
      remove('user');
      setUserState(null);
      router.push(location.pathname === '/directory' ? '/auth/sign-in' : '/');
    })
  }

  const logoImgErrorHandler = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const target = e.target as IHasSrc;
    if (target) {
      target.src = '/images/directory/user_default-logo.svg';
      console.clear();
    }
  };
  return (
    <div className={`user-dropdown ${isUserPopupActive ? 'active' : ''} ${isMobile ? 'mobOpen' : ''}`}>
      <div className="user-dropdown__header">
        <img
          className="header-icon"
          alt="DFA user icon"
          src={userData?.logo ?? '/images/directory/user_default-logo.svg'}
          onError={logoImgErrorHandler}
        />
        <div className="header-context">
          <h4 className="header-context__username">Hi {userData?.first_name || 'Unknown user'}!</h4>
          <p className="header-context__company">{userData?.company || 'Support'}</p>
        </div>
      </div>
      <div className="user-dropdown__context">
        {!userData?.isAdmin && (
          <a className="profile-line profile-status" href="/directory/virtual-office">
            Profile
            <span className={userProfileStatus}>{userProfileStatus}</span>
          </a>
        )}
        <button className="signout-btn" onClick={logout}>
          Sign out
        </button>
      </div>
      {userData?.type === 'PREMIUM' ? (
        <div className="user-dropdown__footer">
          <p className="expires-status">
            {/*DFA Member Expires <span>{userData.validity ? validityDate.toString() : 'no data'}</span>*/}
            DFA Member Expires <span>{validityDate}</span>
          </p>
          <a className="expires-btn" href="/membership">
            Renew membership
          </a>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserDropdown;
