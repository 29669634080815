export interface Route {
  link: string;
  label: string;
  admin?: boolean;
  group?: Route[] | any;
}

const routes: Route[] = [
  {
    link: '',
    label: 'svg',
    admin: true,
  },
  {
    link: '/directory',
    label: 'Directory',
    admin: false,
  },
  {
    link: '/membership',
    label: 'Membership',
    admin: false,
  },
  {
    link: '/benefits',
    label: 'Benefits',
    admin: false,
    group: [
      {
        label: 'Shipping Leads',
        link: '/benefits/shipping-leads',
      },
      {
        label: 'Networking',
        link: '/benefits/networking',
      },
      {
        label: 'Digital Tools',
        link: '/benefits/digital-tools',
      },
      {
        label: 'Support',
        link: '/benefits/support',
      },
      {
        label: 'Payment Protection',
        link: '/benefits/payment-protection',
      },
      {
        label: 'Brand Reliability',
        link: '/benefits/brand-reliability',
      },
    ],
  },
  {
    link: '',
    label: 'Resources',
    admin: false,
    group: [
      {
        label: 'How to Became a Freight Forwarder',
        link: '/freight-forwarder',
      },
      {
        label: 'Freight Dictionary',
        link: '/freight-dictionary',
      },
      {
        label: 'Bill of Lading',
        link: '/resources/bill-of-lading',
      },
      {
        label: 'Demurrage and Detention',
        link: '/resources/demurrage-and-detention',
      },
      {
        label: 'Blog',
        link: '/blog',
      },
    ],
  },
  {
    link: '/events',
    label: 'Events',
    admin: false,
    group: [
      {
        label: 'Monthly Members Meeting',
        link: '/events/members-meeting',
      },
      {
        label: 'Global Freight Summit 2024',
        link: '/events/global-freight-summit',
      },
      // {
      //   label: 'Global Freight Summit 2024',
      //   link: '/events/global-freight-summit-2024',
      // },
      // {
      //   label: 'Global Freight Summit 2023',
      //   link: '/events/global-freight-summit-2023',
      // },
      {
        label: 'Digitalization and Future of Freight Conference',
        link: '/events/future-of-freight',
      },
      {
        label: 'Annual Conference',
        link: '/events/1st-dfa-annual-conference',
      },
      {
        label: 'Show all',
        link: '/events',
      },
    ],
  },
  {
    link: '/company/vision-strategy',
    label: 'Company',
    admin: false,
    group: [
      {
        label: 'Vision & Strategy',
        link: '/company/vision-strategy',
      },
      {
        label: 'Contact Us',
        link: '/company/contact-us',
      },
      {
        label: 'Membership Regulations',
        link: '/resources/membership-regulations',
      },
      {
        label: 'Code of Ethics',
        link: '/resources/code-of-ethics',
      },
      {
        label: 'Help Center',
        link: '/company/help-center',
      },
      {
        label: 'Blacklist',
        link: '/resources/blacklist',
      },
      // {
      //   label: 'Blog',
      //   link: '/blog',
      // },
    ],
  },
];

export default routes;
