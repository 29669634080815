export const isObject = (val: any): boolean => typeof val === 'object';
export const isString = (val: any): boolean => typeof val === 'string';
export const css = (el: HTMLElement, styles: Object = {}): unknown => Object.assign(el.style, styles);
export const isDefined = <T>(argument: T | undefined): argument is T => argument !== undefined;
export const stringify = <T>(obj: T): string => JSON.stringify(obj);
export const isEmptyObject = <T extends Object>(obj: T | null | undefined): boolean => obj ? !Object.entries(obj).length : true;

function slice<T>(array: T[], start: number, end: number): T[] {
  let length: number = array == null ? 0 : array.length;
  if (!length) return [];
  start = start == null ? 0 : start;
  end = end === undefined ? length : end;

  if (start < 0) start = -start > length ? 0 : length + start;
  end = end > length ? length : end;
  if (end < 0) end += length;
  length = start > end ? 0 : (end - start) >>> 0;
  start >>>= 0;

  let index: number = -1;
  const result: T[] = new Array(length);
  while (++index < length) result[index] = array[index + start];
  return result;
}

export function chunk<T>(array: T[], size: number = 1): T[][] {
  size = Math.max(size, 0);
  const length: number = array == null ? 0 : array.length;
  if (!length || size < 1) return [];
  let index: number = 0;
  let resIndex: number = 0;
  const result: T[][] = new Array(Math.ceil(length / size)).fill([]);

  while (index < length) result[resIndex++] = slice<T>(array, index, (index += size));
  return result;
}

export const include = (item: string, value: string): boolean =>
  item.toString().toLowerCase().includes(value.toLowerCase());
export const search = (inputValue: string, arr: any, key?: string): any[] => {
  let result: any[] = [];
  if (key) result = arr.filter((list: any) => include(list[key], inputValue));
  else result = arr.filter((list: any) => include(list, inputValue));
  return result;
};

export const searchSome = (inputValue: string, arr: any, keys: Array<Array<string> | string>): any[] => {
  const result: any[] = [];
  arr.map((list: any) => {
    let can: boolean = false;
    keys.map((key) => {
      if (typeof key === 'string') {
        if (include(list[key], inputValue)) can = true;
        return;
      }
      const [first, second, third] = key;

      if (list[first][second]) {
        if (inputValue.split(' ').length > 1 && list[first][third]) {
          const [name, surname] = inputValue.split(' ');
          if (
            (include(list[first][second], name) && include(list[first][third], surname)) ||
            (include(list[first][third], name) && include(list[first][second], surname))
          )
            can = true;
          return;
        }

        if (include(list[first][second], inputValue)) can = true;
      }
    });
    can && result.push(list);
  });
  return result;
};

export const processPhoneNumber = (phoneNumber: any) => {
  const regExp = /(\s)|(\(|\))|(-)/g;
  return phoneNumber.replaceAll(regExp, '');
};

export const processDate = (date: string) => {
  const separator = /\.|\/|\s/g;
  const [month, day, year] = new Date(date)
    .toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
    .split(separator);
  return `${month} ${day} ${year}`;
};

const transformToAssocArray = (prmstr: string) => {
  const params: any = {};
  const prmarr: string[] = prmstr.split('&');
  for (let i = 0; i < prmarr.length; i++) {
    let tmparr = prmarr[i].split('=');
    params[tmparr[0]] = tmparr[1];
  }
  return params;
};

export const getSearchParameters = () => {
  const prmstr = window.location.search.substr(1);
  return prmstr != null && prmstr != '' ? transformToAssocArray(prmstr) : {};
};

export const getProfileAndHashFromUrl = (pathname: string) => {
  const [, , , profile, hash] = pathname.split('/');
  return { profile, hash };
};

export const checkCookie = (cookieName: string) => {
  let isExist = false;
  const cookiesArray = document.cookie.split(';');
  cookiesArray.forEach((item) => {
    if (item.includes(cookieName)) {
      isExist = true;
    }
  });
  return isExist;
};

export const getCookie = (cookieName: string): string | boolean => {
  let cookie;
  const cookiesArray = document.cookie.split(';');
  cookiesArray.forEach((item) => {
    if (item.includes(cookieName)) {
      cookie = item.split('=')[1];
    }
  });
  return cookie || false;
};

export const random = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min;
export const isComparisonObjects = (obj: any, obj1: any): boolean => JSON.stringify(obj) === JSON.stringify(obj1);

export const changeValueObject =
  <T>(data: T[]) =>
  (obj1: T, obj2: T | undefined): T[] => {
    return data.map((item: T) => (isComparisonObjects(item, obj1) ? obj2 : item)).filter(isDefined);
  };

export const debounce = (fn: (args: any) => void, ms: number) => {
  let timeout: NodeJS.Timeout;

  return function <A>(...args: [A]) {
    clearTimeout(timeout);
    // @ts-ignore
    timeout = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const makeId = (length = 3): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));
  return result;
};

export const getMetaTitle = (title: string): `${string} | Online Air Freight Marketplace` => {
  return `${title} | Online Air Freight Marketplace`;
};
