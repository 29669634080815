// Run 'npm run seo' before project run.
import dates from './result.json'
import dynamicPath from "./dynamicPath";
export type BaseSchemeType = {
  '@context'?: "http://www.schema.org";
  datePublished: string
  dateModified: string
}

abstract class BaseScheme<T extends BaseSchemeType> {
  private scheme: T;

  constructor(path:string | null, base?: boolean) {
    // console.log('path', path)
    this.scheme = {} as T;
    if (base) {
      this.scheme = {
        '@context': 'http://www.schema.org',
      } as T;
    }
    if (path) this.setSeoDate(path)
  }

  searchSeoKey(path: string) {
    const key = dynamicPath[path as keyof typeof dynamicPath];
    return key ?? path;
  }

  private setSeoDate(path:string) {
    const key = this.searchSeoKey(path) as keyof typeof dates;
    const item = dates[key];

    if (item) {
      this.set('datePublished', item.datePublished as any)
      this.set('dateModified', item.dateModified as any)
    }
  }

  public set(key: keyof T, value: T[keyof T]): this {
    this.scheme[key] = value;
    return this
  }
  public setAll = (scheme: T): this => {
    this.scheme = {...this.scheme, ...scheme};
    return this
  }

  public getObject = (): T => this.scheme;
  public getScheme = (): string => JSON.stringify(this.scheme, null, 2);

  public static url = (url:string): string => {
    if (url.startsWith('http')) return url;
    return 'https://www.df-alliance.com' + url;
  }
}

export default BaseScheme;
