import React, {FC, useEffect, useRef} from 'react';
// import useOnClickOutside from '@Hook/useOnClickOutside';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import {css} from 'utils/helper';
import {useParams} from "next/navigation";
import {useRouter} from "next/router";

type Size = 'small' | 'medium' | 'standard';

interface IModal {
  open: boolean;
  setModalVisible: (state: boolean) => void;
  contact?: any;
  callback?: (state: any) => void;
  title?: string;
  text?: string;
  accentText?: string;
  btnText?: string;
  closeIcon?: boolean;
  modalHandler: () => void;
  size?: Size;
  children?: React.ReactElement;
  noIcon?: boolean;
  noRedirection?: boolean;
  modalStyles?: any;
}

const Modal: FC<IModal> = ({
                             open,
                             size = 'standard',
                             setModalVisible,
                             title,
                             text,
                             accentText,
                             btnText,
                             modalHandler,
                             noIcon,
                             closeIcon,
                             noRedirection,
                             modalStyles,
                             children,
                           }): React.ReactElement => {
  const router = useRouter()
  if (router.pathname.includes('auth')) return <></>;

  const modal = useRef<HTMLDivElement>(null);
  const className = size === 'small' ? ' modal--small' : size === 'medium' ? ' modal--medium' : '';

  const closeModal = () => {
    setModalVisible(false);
    if (noRedirection) {
      css(document.body, {overflow: 'visible'});
      return;
    }
    location.href = '/';
  };

  useEffect(() => {
    css(document.body, {overflow: open ? 'hidden' : 'visible'});
  }, [open]);

  useOnClickOutside(modal, () => open && closeModal());

  return (
    <div className={`modal${open ? ' modal--open' : ''}${className}`}>
      <div className="modal__body" style={modalStyles ? modalStyles : {}} ref={modal}>
        {closeIcon
          ?
          <svg className="modal__close-icon" onClick={closeModal} xmlns="http://www.w3.org/2000/svg" width="20"
               height="20" viewBox="0 0 20 20" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.2698 5.21726C5.97931 4.92758 5.50835 4.92758 5.21786 5.21726C4.92738 5.50693 4.92738 5.97659 5.21786 6.26626L9.15496 10.1924L5.21786 14.1185C4.92738 14.4082 4.92738 14.8778 5.21786 15.1675C5.50835 15.4572 5.97931 15.4572 6.2698 15.1675L10.2069 11.2414L14.1438 15.1674C14.4343 15.457 14.9053 15.457 15.1958 15.1674C15.4862 14.8777 15.4862 14.408 15.1958 14.1184L11.2588 10.1924L15.1958 6.26641C15.4862 5.97674 15.4862 5.50708 15.1958 5.21741C14.9053 4.92773 14.4343 4.92773 14.1438 5.21741L10.2069 9.14338L6.2698 5.21726Z"
                  fill="#currentColor"/>
          </svg>
          :
          <></>
        }
        {noIcon ? '' : <img src="/images/main/contact/images/successfully.svg" alt="success image"/>}
        {title ? <div className="modal__body-title">{title}</div> : ''}
        {text ? <p className="modal__body-text">{text}</p> : ''}
        {accentText ? <p className="modal__body-accent-text">{accentText}</p> : ''}

        {children ?? (
          <div className="modal__body-btn btn btn-dark size-xs" onClick={modalHandler}>
            {btnText}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;

