import { apiMap } from 'api/apiMap';
import axios from 'axios';

const logoutHandler = async (callback: () => void) => {
  const { data: response } = await axios.post(apiMap.logout);
  if (response.data.status === 'ok') callback();
  else {
    alert('Logout failed');
    console.log('Logout - response: ', response);
  }
};

export default logoutHandler;
