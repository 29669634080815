import React, {useEffect, useState} from 'react';
import {Provider} from 'react-redux';
import {CookiesProvider, useCookies} from 'react-cookie';
import axios from 'axios';
import {store} from 'redux/store';
import {setLogged} from 'redux/actionCreators/userActions';
import {apiMap} from 'api/apiMap';
import {host} from 'utils/constants';
import Script from 'next/script';
import {webSchemas} from '../lib/schemas';
import Head from 'next/head';
import 'styles/styles.scss';
import GaConnector from '../components/GaConnector';
import localFont from 'next/font/local';
import Modal from '../ui/Modal';

const title = 'Digital Freight Alliance: Logistics Network for Global Freight';

const description =
  'Digital Freight Alliance is a leading Online Logistics Network & Member Portal. Enroll with us and get unique features of our Membership Plan';

const myFont = localFont({src: './Montserrat-VariableFont_wght.ttf'});

function MyApp({Component, pageProps}: any) {
  const getLayout = Component.getLayout || ((page: any) => page);
  const [isUserLogged, setIsUserLogged] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  // Blacklist of pages where membership banner in modal window should not appear:
  const blacklistForMembershipBanner: Array<string> = [
    '/membership',
    '/auth/sign-up',
    '/auth/sign-in',
    '/auth/forgot-password',
    '/auth/confirm-password',
    '/partners/erp-enquiry',
  ];

  const checkPageForBlacklist = () => {
    return blacklistForMembershipBanner.some((item: string) => item === pageProps?.path);
  };

  const handleModal = () => (location.href = '/membership');

  const {
    title: propsTitle,
    description: propsDescription,
    keywords,
    path: pathProps = '/',
    pixelMeta = false,
    authorName,
    image,
    publishDate,
    ...otherProps
  } = pageProps;

  const [{}, setCookie, removeCookie] = useCookies(['user']);

  const fetchData = async () => {
    const {
      data: {data: user},
    } = await axios(apiMap.initialAuthenticate);

    if (user) {
      setCookie(
        'user',
        {
          company: user.company,
          logo: user.logo,
          validity: user.validity,
          first_name: user.first_name,
          type: user.type,
          name: user.name,
          email: user.email,
          phone: user.phone,
          isAdmin: user.isAdmin,
          hash_id: user.hash_id,
          hash: user.hash,
        },
        {path: '/'}
      );
    } else {
      removeCookie('user', {path: '/'});
    }

    store.dispatch(setLogged(!!user?.hash));

    setIsUserLogged(!!user?.hash);

    // console.log('> setting isUserLogged: ', !!user?.hash);

    const app = document.body.querySelector<HTMLDivElement>('#app');
    if (host !== 'loc' && app) app.removeAttribute('data-page');
  };

  const getSchema = () => {
    switch (true) {
      case pathProps.startsWith('/blog'):
        return webSchemas.post({
          title: propsTitle,
          description: propsDescription,
          path: pathProps,
          author: authorName,
          image: image,
          publishDate: publishDate,
        });
      case !!otherProps.post:
        return webSchemas.post({
          title: propsTitle,
          description: propsDescription,
          path: pathProps,
          author: authorName,
          image: image,
          publishDate: publishDate,
          post: otherProps.post,
        });
      case pathProps.startsWith('/freight-dictionary/'):
        return webSchemas.freightDictionaryChildren({
          title: propsTitle,
          path: pathProps,
        });
      case pathProps.startsWith('/freight-forwarder/'):
        return webSchemas.freightForwarderChildren({
          title: propsTitle,
          path: pathProps,
        });
      default: // @ts-ignore
        return webSchemas?.[pathProps]?.(propsTitle ? propsTitle : title, propsDescription ? propsDescription : description, pathProps)
        || webSchemas['/'](propsTitle ? propsTitle : title, propsDescription ? propsDescription : description, pathProps)
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // console.log('> > isUserLogged: ', isUserLogged);
    if (checkPageForBlacklist()) return;

    let timeout: any;
    if (!isUserLogged) {
      timeout = setTimeout(() => {
        setIsModalVisible(true);
      }, 10000);
    }
    return () => clearTimeout(timeout);
  }, [isUserLogged]);

  return (
    <>
      <Head>
        {!!keywords ? (
          <meta name="keywords" content={keywords}/>
        ) : (
          <meta
            name="keywords"
            content="digital freight alliance, freight alliance, freight online, online freight marketplace, ocean freight online, logistics member portal"
          />
        )}

        {!!propsTitle ? (
          <>
            <title>{propsTitle}</title>
            <meta property="og:title" content={propsTitle}/>
            <meta name="twitter:title" content={propsTitle}/>
            {/*<title>{propsTitle + ' | Digital Freight Alliance'}</title>*/}
            {/*<meta property="og:title" content={`${propsTitle} | Digital Freight Alliance`} />*/}
            {/*<meta name="twitter:title" content={`${propsTitle} | Digital Freight Alliance`} />*/}
          </>
        ) : (
          <>
            <title>{title}</title>
            <meta property="og:title" content={title}/>
            <meta name="twitter:title" content={title}/>
          </>
        )}

        {!!propsDescription ? (
          <>
            <meta property="og:description" content={propsDescription}/>
            <meta name="twitter:description" content={propsDescription}/>
            <meta name="twitter:image" content={propsDescription}/>
            <meta name="description" content={propsDescription}/>
          </>
        ) : (
          <>
            <meta property="og:description" content={description}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content={description}/>
            <meta name="description" content={description}/>
          </>
        )}
        <meta property="og:site_name" content="DF Alliance"/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content="https://www.df-alliance.com/images/global/logo.svg"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="DF Alliance"/>
        <meta name="twitter:image" content="https://www.df-alliance.com/images/global/logo.svg"/>
        <link rel="icon" href="/images/favicon.ico"/>
        {process.env.NODE_ENV === 'production' && (
          <>
            <meta property="og:url" content={`https://www.df-alliance.com${pathProps}`}/>
            <link rel="canonical" href={`https://www.df-alliance.com${pathProps}`}/>
            <meta name="google-site-verification" content="mZjCSKb2qrksNA7tbDfY-8aP7DIM2f4H47ukvHAGIZc"/>
          </>
        )}

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{__html: getSchema()}}
        />
      </Head>

      <main className={myFont.className}>
        <GaConnector/>
        {/*development*/}
        {process.env.NODE_ENV === 'development' && (
          <Script id="development">{`window.dataLayer = [];console.log('development mode');`}</Script>
        )}

        {/*production*/}
        {process.env.NODE_ENV === 'production' && (
          <>
            <Script strategy="lazyOnload" id="gaconncetorScript" src="/js/libs/gaconnector.js"></Script>

            <Script strategy="lazyOnload" id="googletagmanager">
              {`
                (function(w,d,s,l,i) {
                w[l]=w[l]||[];
                w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                j.async=true;
                j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-KMVBDKQ');
                `}
            </Script>
          </>
        )}

        {!!pixelMeta && (
          <>
            <Script
              id="checkpoint"
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `
                !function(f,b,e,v,n,t,s){
                  if(f.fbq) return;
                  n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://checkpoint.url-protection.com/v1/url?o=https%3A//connect.facebook.net/en_US/fbevents.js&g=ZDJhMWM3YTcyODhjNGU5Mw==&h=YTNmYTBkNDEzY2EyNjQzN2YxNjMzMGE0YWFjMzIzY2ZkMjc3ZjQ5MDY4MGZkYTZlMmMwM2E0MzMyZWViOGM3ZA==&p=Y3AxZTpkcHdvcmxkOmM6bzpkNzM2MjQwZGI4MDg5NGM2YTAzNTRjMjI1MDNkNTM0Mzp2MTp0OkY=');
                fbq('init', '725562121739915');
                fbq('track', 'PageView');
          `,
              }}
            />

            <noscript>
              <img
                alt="noscript"
                height="1"
                width="1"
                style={{display: 'none'}}
                src="https://www.facebook.com/tr?id=725562121739915&ev=PageView&noscript=1/"
              />
            </noscript>
          </>
        )}

        {getLayout(
          <CookiesProvider>
            <Provider store={store}>
              <Component {...otherProps} />
            </Provider>
          </CookiesProvider>
        )}
      </main>

      <Modal
        title="Welcome to DFA!"
        text="The Digital Freight Alliance is the logistics association created in 2020 by DP World to unite logistics companies in one community, optimizing processes and using digital tools for logistics"
        accentText="All Members receive 5 unique tools"
        btnText="Join now"
        noIcon={true}
        noRedirection={true}
        modalHandler={handleModal}
        closeIcon={true}
        open={isModalVisible}
        setModalVisible={setIsModalVisible}
        contact={''}
      />
    </>
  );
}

export default MyApp;
